import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import dateFormat from "dateformat";
import format from 'date-fns/format';
import { AbsenseTypeOptions, dateFields } from "../constants/HCPConstant";

export const checkUndefinedAndNull = (obj) => {
  return obj !== undefined && obj !== null
}
export const checkUndefinedAndNullEmpty = (obj) => {
  return checkUndefinedAndNull(obj) && obj !== ""
}
export const checkUndefinedAndNullLength = (obj) => {
  return checkUndefinedAndNull(obj) && obj.length > 0
}

export const checkTextLengthForBreakText = (string) => {
  if (string.length > 11) {
    const up = string.slice(0, 11) + ' ' + string.slice(11);
    return up;
  }
  else
    return string;
}

export const checkForEmailBreak = (email) => {
  if (email.length > 16) {
    const emailParts = email.split('@');
    return emailParts[0] + '@ ' + emailParts[1];
  }
  else
    return email;
}

export const bindReportFields = (tableData, oldFields, newFields) => {
  var masterDataExport: any = [];

  tableData.map(function (obj) {
    var attr: any = {};
    oldFields.forEach((field) => {
      var s: any = {
        [field]: obj[field],
      };
      attr = { ...attr, ...s };
    });
    masterDataExport.push(attr);
  });

  // Replace default dates with empty string
  masterDataExport.forEach((obj) => {
    Object.keys(obj).forEach((field) => {
      if (dateFields.includes(field)) {
        if (obj[field] === '1900-01-01T00:00:00' || obj[field] === '1900-01-01T00:00:00.000') {
          obj[field] = ''; // Replace default date with empty string
        }
        else {
          obj[field] = formatDate(obj[field]);
        }
      }
      if (field === 'absense' && obj[field]) {
        obj[field] = absenceTypeMapping[obj[field]] || obj[field];
      }    
    });

    // Rename keys according to newFields array
    Object.keys(obj).forEach((key, index) => {
      renameKey(obj, key, newFields[index]);
    });
  });
  return masterDataExport;
};

function renameKey(obj, oldKey, newKey) {
  obj[newKey] = obj[oldKey];
  delete obj[oldKey];
};
const absenceTypeMapping = AbsenseTypeOptions.reduce((acc, option) => {
  acc[option.value] = option.name;
  return acc;
}, {});

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToCSV = (apiData, fileName) => {
  const ws : any = XLSX.utils.json_to_sheet(apiData);
  // Manually set the FTE column to be numeric
  const range = XLSX.utils.decode_range(ws['!ref']);
  const fteColumnIndex = ws["!cols"]?.findIndex((col) => col?.header?.includes("FTE"));

  if (fteColumnIndex !== -1) {
  for (let R = range.s.r + 1; R <= range.e.r; ++R) { // Starting from row 1 to skip the header
    const cell_address = XLSX.utils.encode_cell({ c: fteColumnIndex, r: R }); // Assuming FTE is the second column (index 1)
    if (ws[cell_address]) {
      ws[cell_address].t = 'n'; // Set the cell type to number
      ws[cell_address].v = Number(ws[cell_address].v); // Ensure the value is a number
      ws[cell_address].z = '0.00'; // Set the cell format to display two decimal places
    }
  }
}
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

const DEFAULT_DATE_STRING = "1900-01-01T00:00:00";

const removeTime = (value) => dateFormat(value, "yyyy-mm-dd");

export const formatDate = (date) => {
  if (date === DEFAULT_DATE_STRING) {
    return "";
  }
  else
    return removeTime(date);
};

export const getOnDateFilteredData = (allData, onDate) => {
  //let columnToShow: any[] = []
  if (allData.length > 0) {
    const columnToShow = allData.map(item => {
      const eDate = formatDate(item.eD_FTE);
      const startDate = new Date(item.sD_FTE);
      const endDate = new Date(item.eD_FTE);

      const isFTERange = eDate === "" ? onDate >= startDate : onDate >= startDate && onDate <= endDate;

      if (!isFTERange) {
        item.fte = "";
        item.sD_FTE = DEFAULT_DATE_STRING;
        item.eD_FTE = DEFAULT_DATE_STRING;
      }

      return item;
    });


    // allData.map((item:any) => {      

    //     let eDate = formatDate(item.eD_FTE);
    //     const startDate = new Date(item.sD_FTE);
    //     const endDate = new Date(item.eD_FTE);

    //     let isFTERange = false;
    //     if(eDate === ""){
    //       if(onDate >= startDate)
    //         isFTERange = true;
    //       else
    //         isFTERange = false;
    //     }
    //     else
    //     {
    //       if(onDate >= startDate && onDate <= endDate)
    //         isFTERange = true;
    //       else
    //         isFTERange = false;
    //     }

    //   if(!isFTERange){
    //     item.fte = "";
    //     item.sD_FTE = DEFAULT_DATE_STRING;
    //     item.eD_FTE = DEFAULT_DATE_STRING;
    //   }
    //   columnToShow.push(item);
    // });        
    //let dateFormat = "2023-01-12T00:00:00"
    // const formattedDate = format(onDate, "yyyy-MM-dd'T'00:00:00");
    // let newData = allData.find(item => item.sD_FTE <= formattedDate || item.eD_FTE >= formattedDate);
    // return newData;
    return columnToShow;
  }
  return null;
};

export const getNameById = (id, data, isCostCenter = false) => {
  const numericId = parseInt(id); // Convert id to a number
  const item = data.find((element) => element.id === numericId);
  return item ? (isCostCenter ? item.costCenterNo : item.name) : id;
}; 

export const breakEmailAtSymbol = (email) => {    
  return email.replace('@', '@<wbr>');
};
